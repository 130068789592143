import { useRouter } from 'next/router'
import { useAppDispatch } from '@root/store'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'

export default function userRedirectOnLogout() {
  const router = useRouter()
  //TODO: Fix this on Next 16
  const currentPath = router.asPath as string

  const redirectOutsideMyVidz = () => {
    const myVidzUrl = [
      UrlPathsEnum.MY_VIDZ as string,
      UrlPathsEnum.MY_VIDZ_VIDEO_REVIEW as string,
      UrlPathsEnum.MY_VIDZ_SUBSCRIPTIONS as string,
    ]

    if (myVidzUrl.includes(currentPath)) {
      router.push(UrlPathsEnum.EXPLORE)
    }
  }

  return { redirectOutsideMyVidz }
}
